<template>
  <div @load="loading()">
    <div class="touch_top" @touchstart='touchstart' @touchmove='touchmove' :swipe-options="{direction: 'horizontal'}">
      <topMb></topMb>
      <!-- 轮播 -->
      <el-carousel :height="banH">
        <el-carousel-item v-for="item in bannerMb" :key="item" class="carousel-item">
          <img ref="imgH" class="carousel-img" :src="item" @load="setbanH()" />
        </el-carousel-item>
      </el-carousel>
      <!-- 切换 -->
      <div class="main-travel ">
        <div class="train-box">
          <div class="app-container">
            <div class="col-md-12 travel-item-box">
              <el-image src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-1.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <div class="travel-item-md6" v-for="(item,i) in ticketArr" :key="i" @click="change(item)">
                <div :class="[active===item.id ? 'travel-item1' : 'travel-item']">
                  <el-image class="travel-item-img" :src="item.img" lazy></el-image>
                  <p class="travel-right">有效日
                    <span class="travel-right-day">
                      <span :style="{'color':item.fontcolor}">{{item.validityDay}}</span>&nbsp;天</span>
                  </p>
                  <p class="travel-detail" v-for="(item ,index) in splitBatName(item.describe)" :key="index">{{item}}</p>
                  <p class="travel-price" :style="{'color':item.fontcolor}">￥{{item.price}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 套餐内容 -->
      <div class="main-package">
        <div class="app-container">
          <div class="col-md-12  package-content-box">
            <div class="package-content flex-arround">
              <el-image class="package-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <p class="package-title">ALL PASS套餐內容</p>
              <el-image class="package-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
            </div>
          </div>
          <div class="col-md-12 flex-justify-c">
            <div class="package-riding-content2">
              <div class="riding-box">
                <div class="riding-right">
                  <p class="riding-right-title">JR乘车券</p>
                  <p class="right-position">能够搭乘指定座位</p>
                  <p class="riding-right-title2">关西特快列车HARUKA单程券乘坐票</p>
                  <p class="riding-right-title22">(关西机场站→京都站)</p>
                  <div class="riding-line"></div>
                  <p class="riding-right-title3">您也可以将关西特快列车HARUKA<br>列车单程票券更改为JR西日本铁路周游券</p>
                  <el-image class="riding-right-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/riding-2.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <el-image class="riding-copyright" :src="copyright" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" style="width: 309px;height:10px;margin-left: -12px;" alt="" />
                    </div>
                  </el-image>
                  <div class="col-md-12 five_days_box">
                    <div class="five_days_title" :class="[active===2 ? 'riding-bottom-1-1' : '']">
                      <p>{{planName_small}}</p>
                      <p>￥{{planPrice}}</p>
                    </div>
                  </div>
                  <div class="col-md-12 five_days_box" v-if="planName2_small && planPrice2" :class="[active===2 ? 'riding-bottom-2-1' : '']">
                    <div class="five_days_title">
                      <p>{{planName2_small}}</p>
                      <p>￥{{planPrice2}}</p>
                    </div>
                  </div>
                  <div class="col-md-12 five_days_box" v-if="planName3 && planPrice3">
                    <div class="five_days_title">
                      <p>{{planName3}}</p>
                      <p>￥{{planPrice3}}</p>
                    </div>
                  </div>
                  <div class="col-md-12 five_days_box">
                    <div class="five_days_attention">
                      <p>※仅一次有效</p>
                      <p>※也能够于天王寺•新大阪站下车</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- 日本环球影城 -->
          <div class="package-universal-content2 ">
            <div class="universal-left">
              <p class="universal-left-1">日本环球影城<br>1日周游券</p>
              <el-image class="lazy universal-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/universal-zh1.jpg" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <p class="universal-left-3">
                <span>不仅有荣获主题公园业界最高荣誉<br>“Thea奖”的全球首创*马力欧园区和<br>“马力欧卡丁车～酷霸王的挑战书～™”，</span>
                <span>还有众多如哈利波特的魔法世界™及<br>小黄人等成年人与儿童均可<br>尽享其乐的大规模项目！</span>
                <span>在超感动、超兴奋的影城，<br>收获满满能量吧！</span>
              </p>
              <div class="universal-left-4">
                <p>*合同会社U・S・J针对以任天堂品牌的角色及其世界为主题的设施开展的调查结果</p>
              </div>
            </div>
          </div>
          <!-- 观光设施共通劵 -->
          <div class="facilities-content2 ">
            <div class="facilities-box flex-arround">
              <el-image class="lazy facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <p class="facilities-title">观光设施共通券<br><span>{{md3[0].num}}项设施</span></p>
              <el-image class="lazy facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
            </div>
            <div class="facilities-attention2 ">
              <p>1.各设施有可能临时休业的形况，当日出发游玩前请务必<br>确认官网最新消息及营业时间。</p>
              <p>2.欲前往下个游玩景点，需至少间隔2小时，才能扫码入场。</p>
              <p>3.同一个设施不可重复入场。</p>
            </div>
            <div class="area-box">
              <div class="area-Box">
                <div class="col-md-12">
                  <div class="all-area" :style="{backgroundColor:(activeArr.includes(1)?'#ff7b00':'#fff'),color:(activeArr.includes(1)?'#fff':'#ff7b00')}" @click="activeArea({id:1,areaType:0})">所有地区
                  </div>
                </div>
                <div class="col-md-12" style="display: flex;justify-content: space-between;">
                  <div class="area" :style="{backgroundColor:(activeArr.includes(2)?'#ff7b00':'#fff'),color:(activeArr.includes(2)?'#fff':'#ff7b00')}" @click="activeArea({id:2,areaType:2})">大阪</div>
                  <div class="area" :style="{backgroundColor:(activeArr.includes(3)?'#ff7b00':'#fff'),color:(activeArr.includes(3)?'#fff':'#ff7b00')}" @click="activeArea({id:3,areaType:1})">京都</div>
                </div>
                <div class="col-md-12">
                  <div class="area2" :style="{backgroundColor:(activeArr.includes(5)?'#ff7b00':'#fff'),color:(activeArr.includes(5)?'#fff':'#ff7b00')}" @click="activeArea({id:5,areaType:3})">滋贺·兵库·和歌山</div>
                  <div class="col-md-12" style="display: flex;justify-content: space-between;">
                    <div class="area-md3" v-show="this.active===1" :style="{backgroundColor:(activeArr.includes(4)?'#ff7b00':'#fff'),color:(activeArr.includes(4)?'#fff':'#ff7b00')}" @click="activeArea({id:4,areaType:5})">石川</div>
                    <div class="area3" v-show="this.active===1" :style="{backgroundColor:(activeArr.includes(6)?'#ff7b00':'#fff'),color:(activeArr.includes(6)?'#fff':'#ff7b00')}" @click="activeArea({id:6,areaType:4})">山阴山阳</div>
                  </div>
                </div>
                <div class="col-md-12 search-bg" style="display: flex; justify-content: center;">
                  <div class="area-search" @click="drawer = true">搜寻</div>
                </div>
              </div>
              <div class="area-small-attention">
                <p>注意事项</p>
                <p>*ALL PASS的有效期为标题所示，与区域周游券的有效期无关。</p>
                <p>*购买了关西特快列车HARUKA列车的单程票券以及区域周游券的顾客请务必在ALL PASS的有效期间的<br>最终有效日之前进行兑换。</p>
                <p>*如果在有效期间的最终有效日未能进行兑换的话则无法使用。</p>
              </div>
              <el-image class="area-small-bg" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/small_bg.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 抽屉 -->
    <div class="touch" @touchstart='touchstart' @touchmove='touchmove1' style="touch-action: pan-y!important;">
      <el-drawer title="" :visible.sync="drawer" :with-header="true" :destroy-on-close="true">
        <!-- 观光设施 抽屉-->
        <div class="main-facilities">
          <div class="facilities-box2">
            <el-image class="facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy>
              <div slot="placeholder" class="image-slot">
                <img :src="loadImg" class="loadImg" alt="" />
              </div>
            </el-image>
            <p class="facilities-title">观光设施</p>
            <el-image class="facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy>
              <div slot="placeholder" class="image-slot">
                <img :src="loadImg" class="loadImg" alt="" />
              </div>
            </el-image>
          </div>
          <div class="col-md-12">
            <div class="area-title">
              <div class="area-title1">
                <div class="area-title-item">
                  <span :style="{backgroundColor:((activeArr.includes(2) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(2) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(2) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:2,areaType:2})">大阪</span>
                </div>
                <div class="area-title-item" :style="{borderRight:(this.active===1?'1px solid #ff7b00':'0')}">
                  <span :style="{backgroundColor:((activeArr.includes(3) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(3) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(3) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:3,areaType:1})">京都</span>
                </div>
                <div class="area-title-item2" v-show="this.active===1">
                  <span :style="{backgroundColor:((activeArr.includes(6) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(6) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(6) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:6,areaType:4})">山阴山阳</span>
                </div>
              </div>
              <div class="area-title1">
                <div class="area-title-item" v-show="this.active===1">
                  <span :style="{backgroundColor:((activeArr.includes(4) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(4) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(4) || activeArr.includes(1))?'10px':'0'),width:(activeArr.includes(4)?'52px':'52px')}" @click="activeArea({id:4,areaType:5})">石川</span>
                </div>
                <div class="area-title-item1">
                  <span :style="{backgroundColor:((activeArr.includes(5) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(5) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(5) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:5,areaType:3})">滋贺·兵库·和歌山</span>
                </div>
              </div>
            </div>
          </div>
          <div class="facilities-item-box">
            <div class="col-md-12" v-for="(item,i) in showList" :key="i" @click="openSmall(item)">
              <div class="facilities-item" v-if="active=== 1">
                <el-image class="facilities-item-img" :src="item.img" lazy>
                  <div slot="placeholder" class="image-slot">
                    <img :src="loadImg" class="loadImg" alt="" />
                  </div>
                </el-image>
                <div class="facilities-item-title">
                  <p style="width: 293px;margin-bottom: 0;;" v-for="(item ,index) in splitBatName(item.ticketName3)" :key="index">
                    {{item}}</p>
                </div>
              </div>
              <div class="facilities-item" v-else v-show="active === 2 && item.activeId === 1">
                <el-image class="facilities-item-img" :src="item.img" lazy>
                  <div slot="placeholder" class="image-slot">
                    <img :src="loadImg" class="loadImg" alt="" />
                  </div>
                </el-image>
                <div class="facilities-item-title">
                  <p style="width: 293px;margin-bottom: 0;;" v-for="(item ,index) in splitBatName(item.ticketName3)" :key="index">
                    {{item}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- 底部底图 -->
          <div class="language-bg-box">
            <div class="demo-image__lazy">
              <el-image class="language-bg" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/small_bg.png" style="width: 100%;"></el-image>
            </div>
          </div>
        </div>
        <!-- 观光设施详情页 -->
        <el-drawer title="" :append-to-body="true" :visible.sync="innerDrawer">
          <div class="touch" @touchstart='touchstart' @touchmove='touchmove2'>
            <el-carousel height="210px" :interval="2000" ref="nop" arrow="never">
              <el-carousel-item v-for="(item,index) in ticketDetials.tickeImg" :key="index">
                <img :src="item" style="height:100%;width:100%;" lazy />
              </el-carousel-item>
              <!-- </div> -->
            </el-carousel>
            <div class="main-facility">
              <div class="app-container">
                <p class="travel-copyright" v-if="ticketDetials.copyright">{{ticketDetials.copyright}}</p>
                <div class="col-md-12">
                  <div class="facility-title">{{ticketDetials.areaNAme}}</div>
                </div>
                <div class="col-md-12 facility-title2-box">
                  <div class="facility-title2">
                    <p v-for="(item ,index) in splitBatName(ticketDetials.ticketName4)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/facility_area.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <!-- <p class="facility-travel-p">
                    <a :href="ticketDetials.Http" target=_ blank>{{ticketDetials.Http}}</a>
                  </p> -->
                  <p class="facility-travel-p">
                    <a :href="ticketDetials.Http" target=_ blank>{{ticketDetials.Http}}</a>
                    <a :href="ticketDetials.Http2" target=_ blank>{{ticketDetials.Http2}}</a>
                  </p>
                  <!-- <p class="facility-travel-p" style="margin-left: 0;color: #000;">{{ticketDetials.Http2}}</p> -->
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-facilities.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.Faci)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-cart.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.Doer)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-name.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.time)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12 facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-calendar.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.Calendar)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12 facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-price.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <p class="facility-travel-p">￥{{ticketDetials.price}}</p>
                </div>
                <div class="col-md-12 facility-travel-box" v-if="ticketDetials.content2">
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.content2)" :key="index">{{item}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-drawer>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import topMb from "@/components/top-all/top-Mball.vue";
export default {
  components: { topMb },
  data() {
    return {
      banH: "",
      active: 1,
      animateTime: 3000, // 要和 css 的过渡一致
      ticketDescribe: "观光设施共通券(29项设施)",
      planName: "关西广域周游券5天套票",
      planName_small: "关西广域周游券5天套餐",
      planPrice: "27,700",
      planName2: "关西广岛周游券5天套票",
      planName2_small: "关西广岛周游券5天套餐",
      planPrice2: "32,000",
      planName3: "JR西日本全地区铁路周游券",
      planPrice3: "39,000",
      ticketDetials: {},
      showDialog: false,
      innerDrawer: false,
      drawer: false,
      startX: "",
      startY: "",
      moveY: "",
      moveX: "",
      startTime: "",
      activeArr: [1],
      activeAreaArr: [],
      showList: [],
      loadImg: require("@/assets/images/hfkansai/loading.png"),
      copyright: require("@/assets/images/allpass/copyright.png"),
      bannerMb: [
        "https://qbcdn.jtrchina.com/tripelletexploreallpass/banner/mb_banner.png",
        require("@/assets/images/allpass/MB_ch.png"),
      ],
      ticketArr: [
        {
          id: 1,
          type: 1,
          fontcolor: "#F98304",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-11.png",
          ticketName: "西日本探游券",
          validityDay: 4,
          price: "21,000~",
          describe: [
            "JR乘车券+",
            "观光设施共通券29设施+",
            "日本环球影城1日周游券",
          ],
        },
        {
          id: 2,
          type: 2,
          fontcolor: "#F4B22D",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-2.png",
          ticketName: "关西探游券",
          validityDay: 3,
          price: "16,000~",
          describe: [
            "JR乘车券+",
            "观光设施共通券16设施+",
            "日本环球影城1日周游券",
          ],
        },
      ],
      scenicSpotArr: [
        // 京都
        {
          id: 1,
          activeId: 1,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_A.jpg",
          ticketName: ["京都鉄道博物馆入场券"],
          ticketName2: ["京都鉄道博物馆入场券"],
          ticketName3: ["京都鉄道博物馆入场券"],
          ticketName4: ["京都鉄道博物馆入场券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_D.jpg",
          ],
          Doer: ["JR 梅小路京都西站徒步约2分钟"],
          time: ["10:00 ～ 17:00（最后入馆16:30）"],
          Http: "http://www.kyotorailwaymuseum.jp/sc/",
          Faci: ["京都市下京区观喜寺町", "（京都铁道博物馆售票处）"],
          Calendar: ["星期三，年底年初（可能临时休馆）"],
          areaType: 1,
          price: "1,200",
        },
        {
          id: 2,
          activeId: 1,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_A.jpg",
          ticketName: ["东映太秦映画村入场券"],
          ticketName2: ["东映太秦映画村入场券"],
          ticketName3: ["东映太秦映画村入场券"],
          ticketName4: ["东映太秦映画村入场券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_D.jpg",
          ],
          Doer: ["JR 花园站徒歩约13分钟"],
          time: [
            "10:00 ～ 17:00",
            "※可能有临时休馆的情况发生，",
            "营业时间请参照官方网站。",
          ],
          Http: "http://www.toei-eigamura.com/global/cn/ ",
          Faci: ["京都市右京区太秦东峰冈町10", "（东映太秦映画村售票处）"],
          Calendar: ["请参照官方网站"],
          areaType: 1,
          price: "2,400",
        },
        {
          id: 3,
          activeId: 1,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_A.jpg",
          ticketName: ["岚山美食优惠券", "岚电1日通票"],
          ticketName2: ["岚山美食优惠券", "岚电1日通票"],
          ticketName3: ["岚山美食优惠券", "岚电1日通票"],
          ticketName4: ["岚山美食优惠券", "岚电1日通票"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_D.jpg",
          ],
          Doer: ["JR 嵯峨岚山站徒步约8分钟"],
          time: ["岚山电车「岚山站Information」", "9:00 ～ 18:00"],
          Http: "https://www.tripellet.com/gurumeguri_map2022.pdf",
          Http2: "https://www.tripellet.com/gurumeguri2022.pdf",
          Faci: [
            "京都市右京区嵯峨",
            "天龙寺造路町20-2",
            "（岚电岚山站谘询处）",
          ],
          Calendar: ["※各店家的营业时间，", "请参阅优惠劵上。"],
          areaType: 1,
          price: "1,000",
          isHttp: 1,
        },
        {
          id: 4,
          activeId: 1,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_A.jpg",
          ticketName: ["Sky Hop Bus京都", "双层观光巴士（当日劵）"],
          ticketName2: ["Sky Hop Bus京都", "双层观光巴士（当日劵）"],
          ticketName3: ["Sky Hop Bus京都", "双层观光巴士（当日劵）"],
          ticketName4: ["Sky Hop Bus京都", "双层观光巴士（当日劵）"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_C.jpg",
          ],
          Doer: ["JR 京都站乌丸口徒步约1分钟"],
          time: ["请参照官方网站（可能会临时停开）"],
          Http: "https://skyhopbus.com/kyoto/ ",
          Faci: [
            "京都市下京区乌丸通塩小路下东塩小路町",
            "（京都观定期观光巴士乘车处）",
          ],
          Calendar: ["请参照官方网站"],
          areaType: 1,
          price: "2,500",
        },
        {
          id: 5,
          activeId: 1,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_A.jpg",
          ticketName: ["京都和服体验梦馆", "五条店和服和浴衣", "租借方案"],
          ticketName2: ["京都和服体验梦馆五条店和服和浴衣租借方案"],
          ticketName3: ["京都和服体验梦馆五条店和服和浴衣租借方案"],
          ticketName4: ["京都和服体验梦馆五条店和服和浴衣租借方案"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_D.jpg",
          ],
          Doer: ["JR 京都站搭地下铁乌丸线至五条站", "徒步约3分钟"],
          time: ["10:00 ～ 17:30（最后入馆 16:00）"],
          Http: "https://cn-kyoto.yumeyakata.com/itiran-gojo/",
          Faci: ["京都市下京区塩灶町353"],
          Calendar: ["星期二，年底年初（12/28 ～ 1/3）"],
          areaType: 1,
          price: "3,850",
        },

        {
          id: 6,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_A.jpg",
          ticketName: ["天桥立伊根周游券2日券", "（巴士・船・电缆车）"],
          ticketName2: ["天桥立伊根周游券2日券", "（巴士・船・电缆车）"],
          ticketName3: ["天桥立伊根周游券2日券", "（巴士・船・电缆车）"],
          ticketName4: ["天桥立伊根周游券2日券", "（巴士・船・电缆车）"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_D.jpg",
          ],
          Doer: ["京都丹后铁路天桥立站内"],
          time: ["天桥立Terminal Center", "内观光服务处：10:00 ～ 16:30"],
          Http: "https://www.tankai.jp/tourist_tickets/",
          Faci: ["宫津市字文珠314-2", "（天桥立车站内「天桥立观光协会中心」）"],
          Calendar: ["无"],
          areaType: 1,
          price: "2,600",
        },
        // 大阪
        {
          id: 7,
          activeId: 1,
          areaNAme: "大阪",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_A.jpg",
          ticketName: ["梅田蓝天大厦空中庭园", "眺望台入场券"],
          ticketName2: ["梅田蓝天大厦空中庭园", "眺望台入场券"],
          ticketName3: ["梅田蓝天大厦空中庭园", "眺望台入场券"],
          ticketName4: ["梅田蓝天大厦空中庭园", "眺望台入场券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_D.jpg",
          ],
          Doer: ["JR 大阪站徒步约7分钟"],
          time: ["9:30 ～ 22:30（最后入场22:00）"],
          Http: "https://www.skybldg.co.jp/cn/",
          Faci: ["大阪市北区大淀中1-1-88梅田蓝天大厦", "（39楼售票柜台）"],
          Calendar: ["无"],
          areaType: 2,
          price: "1,500",
        },
        {
          id: 8,
          activeId: 1,
          areaNAme: "大阪",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_A.jpg",
          ticketName: ["日本最高楼—阿倍野「HARUKAS 300」展望台"],
          ticketName2: ["日本最高楼—阿倍野「HARUKAS 300」展望台"],
          ticketName3: ["日本最高楼—阿倍野「HARUKAS 300」展望台"],
          ticketName4: ["日本最高楼—阿倍野「HARUKAS 300」展望台"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_D.jpg",
          ],
          Doer: ["JR 天王寺站旁"],
          time: ["9:00 ～ 22:00（最后入场 19:30）"],
          Http: "https://www.abenoharukas-300.jp/zh/",
          Faci: ["大阪市阿倍野区阿倍野筋1-1-43", "（16层服务台）"],
          Calendar: ["无"],
          areaType: 2,
          price: "1,500",
        },
        {
          id: 9,
          activeId: 1,
          areaNAme: "大阪",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_A1.jpg",
          ticketName: ["大阪格兰比亚大酒店", "甜点交响曲"],
          ticketName2: ["大阪格兰比亚大酒店甜点交响曲"],
          ticketName3: ["大阪格兰比亚大酒店", "甜点交响曲"],
          ticketName4: ["大阪格兰比亚大酒店", "甜点交响曲"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_A1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_B1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_C1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_D1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_E1.jpg",
          ],
          Doer: ["连接着JR 大阪站 中央口"],
          time: [
            "甜点提供时间：11:00 ~ 16:00",
            "平日：10:00 ~ 20:00（Lo19:30）",
            "星期六：9:30 ~ 20:00（Lo19:30）",
            "星期日 节日：9:30 ~18:00（Lo17:30）",
          ],
          Http: "https://www.hotelgranviaosaka.jp/zh/dining/lobby-lounge/",
          Faci: ["大阪市北区梅田3丁目1-1（1楼柜檯）"],
          Calendar: ["无"],
          areaType: 2,
          price: "2,300",
          contentL: "",
          content2: ["※图片仅供参考", "※餐点内容会因季节而有所不同"],
        },
        {
          id: 10,
          activeId: 1,
          areaNAme: "大阪",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_A.jpg",
          ticketName: ["大阪水上巴士"],
          ticketName2: ["大阪水上巴士"],
          ticketName3: ["大阪水上巴士"],
          ticketName4: ["大阪水上巴士"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_D.jpg",
          ],
          Doer: ["JR 环状线「大阪城公园站」徒步约3分钟"],
          time: [
            "11:00 / 11:45 / 12:30",
            "13:15 / 14:00 / 14:45 / 15:30 / 16:15",
          ],
          Http: " http://suijo-bus.osaka",
          Faci: [
            "大阪市中央區大阪城2番地前",
            "（水上巴士搭乘处，大阪城港乗船券卖场）",
          ],
          Calendar: ["运休时段为：平日运休2、7/25下午时段", "1月中旬、2月上旬"],
          areaType: 2,
          price: "1,600",
          content: "",
          content2: ["※春季（3月下旬 ～ 4月上旬）有特别价格。"],
        },
        {
          id: 11,
          activeId: 1,
          areaNAme: "大阪",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_A.jpg",
          ticketName: ["大阪市立人居博物馆", "「大阪生活今昔馆」入场券"],
          ticketName2: ["大阪市立人居博物馆", "「大阪生活今昔馆」入场券"],
          ticketName3: ["大阪市立人居博物馆", "「大阪生活今昔馆」入场券"],
          ticketName4: ["大阪市立人居博物馆", "「大阪生活今昔馆」入场券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_C.jpg",
          ],
          Doer: ["JR 天满桥站徒步约7分钟"],
          time: ["10:00 ～ 17:00（最后入馆 16:30）"],
          Http: "http://konjyakukan.com/",
          Faci: ["大阪市北区天神桥6-4-20", "（大阪市立人情报中心8F）"],
          Calendar: ["星期二，年底年初（12/28 ～ 1/4）"],
          areaType: 2,
          price: "400",
        },
        // 滋賀・兵庫・和歌山
        {
          id: 12,
          activeId: 1,
          areaNAme: "兵库",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_A1.jpg",
          ticketName: ["神户布引香草园入场劵", "及缆车乘车劵"],
          ticketName2: ["神户布引香草园入场劵", "及缆车乘车劵"],
          ticketName3: ["神户布引香草园入场劵", "及缆车乘车劵"],
          ticketName4: ["神户布引香草园入场劵", "及缆车乘车劵"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_A1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_B1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_C1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_D1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_E1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_F1.jpg",
          ],
          Doer: ["JR 新神户站徒步5分钟"],
          time: ["山麓站4F 服务中心：9:30 ～ 16:45"],
          Http: "https://www.kobeherb.com/cn/",
          Faci: ["神户市中央区北野町1-4-3", "（山麓站4楼咨询台）"],
          Calendar: ["请参照官方网站"],
          areaType: 3,
          price: "1,800",
        },
        {
          id: 13,
          activeId: 1,
          areaNAme: "兵库",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_A.jpg",
          ticketName: ["明石寿司「技之逸品」", "2000日圆优惠券"],
          ticketName2: ["明石寿司「技之逸品」", "2000日圆优惠券"],
          ticketName3: ["明石寿司「技之逸品」", "2000日圆优惠券"],
          ticketName4: ["明石寿司「技之逸品」", "2000日圆优惠券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_D.jpg",
          ],
          Doer: ["JR 明石站旁"],
          time: ["平日：9:00 ～ 19:00", "星期六日 节日：9:00 ～ 18:00"],
          Http: "https://www.yokoso-akashi.jp/sushi_coupon",
          Faci: [
            "兵库县明石市大明石町1-1-23",
            "（明石车站Akashi谘询处 Piole西馆）",
          ],
          Calendar: ["年底年初（12/31 ～ 1/1）"],
          areaType: 3,
          price: "2,000",
        },
        {
          id: 14,
          activeId: 1,
          areaNAme: "兵库",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_A.jpg",
          ticketName: ["兵库县明石市立", "天文科学馆入馆券"],
          ticketName2: ["兵库县明石市立天文科学馆", "入馆券"],
          ticketName3: ["兵库县明石市立天文科学馆", "入馆券"],
          ticketName4: ["兵库县明石市立天文科学馆", "入馆券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_D.jpg",
          ],
          Doer: ["JR 明石站徒歩约15分钟", "山阳电车人丸前站徒歩约3分钟"],
          time: [
            "明石介绍所",
            "平日：9:00 ～ 19:00",
            "假日：9:00 ～ 18:00 ",
            " 明石市立天文科学馆",
            "9:30 ～ 17:00（最后入馆 16:30）",
          ],
          Http: "https://www.am12.jp/",
          Faci: [
            "兑换地点：兵库县明石市",
            "大明石町1-1-23piole明石西馆",
            "设施地址：兵库県明石市人丸町2-6",
          ],
          Calendar: ["年底年初（可能临时休館）"],
          areaType: 3,
          price: "700",
        },
        {
          id: 15,
          areaNAme: "兵库",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
          ticketName: ["JENOVA号淡路岛", "（明石⇔岩屋）单程船票"],
          ticketName2: ["JENOVA号淡路岛", "（明石⇔岩屋）单程船票"],
          ticketName3: ["JENOVA号淡路岛", "（明石⇔岩屋）单程船票"],
          ticketName4: ["JENOVA号淡路岛", "（明石⇔岩屋）单程船票"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_C.jpg",
          ],
          Doer: ["JR 明石站徒步约8分钟"],
          time: [
            "（平日）",
            "明石乘船时间：9:00 ～ 17:00",
            "岩屋乘船时间：9:00 ～ 17:00",
            "（星期六日 节日）",
            "明石乘船时间：9:00 ～ 17:00",
            "岩屋乘船时间： 9:00 ～ 17:00",
            "※详细时刻表请参照官方网站",
          ],
          Http: "http://www.jenova-line.co.jp/jikoku.php",
          Faci: [
            "明石乘船处：兵库県明石市本町2-10-1",
            "岩屋乘船处：兵库県淡路市岩屋925-27",
            "※可在任何一侧搭乘JENOVA船",
          ],
          Calendar: ["天候不佳时将会停驶"],
          areaType: 3,
          price: "530",
        },
        {
          id: 16,
          activeId: 1,
          areaNAme: "滋贺",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_A.jpg",
          ticketName: ["滋贺长滨浪漫景点", "实体护照", "（市内观光设施通票）"],
          ticketName2: ["滋贺长滨浪漫景点实体护照", "（市内观光设施通票）"],
          ticketName3: ["滋贺长滨浪漫景点实体护照", "（市内观光设施通票）"],
          ticketName4: ["滋贺长滨浪漫景点实体护照", "（市内观光设施通票）"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_D.jpg",
          ],
          Doer: ["JR 长滨站内"],
          time: ["长滨站观光服务处：9:15 ～ 16:45"],
          Http: "https://kitabiwako.jp/spot/spot_8966",
          Faci: ["滋贺县长滨市北船町1−5", "（长滨站观光谘询处)"],
          Calendar: ["年底年初"],
          areaType: 3,
          price: "1,200",
        },
        {
          id: 17,
          activeId: 1,
          areaNAme: "滋贺",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_A.jpg",
          ticketName: ["琵琶湖大津王子大饭店37楼", "湖景餐厅Biona", "午餐套餐"],
          ticketName2: [
            "琵琶湖大津王子大饭店",
            "37楼湖景餐厅Biona",
            "午餐套餐",
          ],
          ticketName3: [
            "琵琶湖大津王子大饭店",
            "37楼湖景餐厅Biona",
            "午餐套餐",
          ],
          ticketName4: [
            "琵琶湖大津王子大饭店",
            "37楼湖景餐厅Biona",
            "午餐套餐",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_E.jpg",
          ],
          Doer: ["JR 大津站可搭乘免费接驳车约10分钟"],
          time: [
            "营业时间： 11:30 ～ 15:00",
            "用餐时间： 11:30 ～ 13:00",
            "13:30 ～ 15:00",
            "（限时90分钟，2个时间段）",
          ],
          Http: "https://www.princehotels.cn/otsu/zh-hans/restaurants/lake-view-dining-biona/",
          Faci: [
            "滋贺县大津市Niono滨4-7-7",
            "琵琶湖大津王子大饭店",
            "（37楼湖景餐厅Biona）",
          ],
          Calendar: ["无"],
          areaType: 3,
          price: "3,000",
        },
        {
          id: 18,
          activeId: 1,
          areaNAme: "滋贺",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_A.jpg",
          ticketName: ["琵琶湖竹生岛观光游览船", "（彦根港～竹生岛往返）"],
          ticketName2: ["琵琶湖竹生岛观光游览船", "（彦根港～竹生岛往返）"],
          ticketName3: ["琵琶湖竹生岛观光游览船", "（彦根港～竹生岛往返）"],
          ticketName4: ["琵琶湖竹生岛观光游览船", "（彦根港～竹生岛往返）"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_D.jpg",
          ],
          Doer: ["JR 彦根站搭乘免费接驳车", "约8分钟于「彦根港」下车"],
          time: ["星期六 日 节日出航"],
          Http: "http://www.ohmitetudo.co.jp/hachimanyama/open-price/index.html/",
          Faci: ["滋贺县彦根市松原町3755", "（彦根港乘船处）"],
          Calendar: ["平日，12/30 ～ 12/31"],
          areaType: 3,
          price: "3,000",
        },
        {
          id: 19,
          areaNAme: "滋贺",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_A.jpg",
          ticketName: ["八幡山缆车往返劵"],
          ticketName2: ["八幡山缆车往返劵"],
          ticketName3: ["八幡山缆车往返劵"],
          ticketName4: ["八幡山缆车往返劵"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_D.jpg",
          ],
          Doer: [
            "JR 近江八幡站北口6号搭乘区",
            "前往长命寺巴士约7分",
            "至大衫町八幡山空中缆车处下车",
          ],
          time: ["9:00 ～ 17:00 （最后上山 16:30）"],
          Http: "http://www.ohmitetudo.co.jp/hachimanyama/open-price/index.html/",
          Faci: ["滋贺县近江八幡市宫内町（山麓售票处）"],
          Calendar: ["无"],
          areaType: 3,
          price: "890",
        },
        {
          id: 20,
          areaNAme: "和歌山",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_A.jpg",
          ticketName: ["和歌山串本海中公园", "水族馆·展望塔入场券"],
          ticketName2: ["和歌山串本海中公园", "水族馆·展望塔入场券"],
          ticketName3: ["和歌山串本海中公园水族馆 ", "展望塔入场券"],
          ticketName4: ["和歌山串本海中公园水族馆 ", "展望塔入场券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_E.jpg",
          ],
          Doer: ["JR 串本站搭乘免费接驳巴士约12分钟"],
          time: ["9:00 ～ 16:30（服务窗口到 16:00）"],
          Http: "http://kushimoto.co.jp/chinese/",
          Faci: [
            "和歌山县東牟婁郡串本町有田1157 ",
            "（和歌山串本海中公园入口处）",
          ],
          Calendar: ["无"],
          areaType: 3,
          price: "1,800",
        },
        {
          id: 21,
          areaNAme: "和歌山",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_A.jpg",
          copyright: "「DESIGNED BY EIJI MITOOKA + DON DESIGN ASSOCIATES」",
          ticketName: ["和歌山电铁贵志川线", "1日乘车券"],
          ticketName2: ["和歌山电铁贵志川线", "1日乘车券"],
          ticketName3: ["和歌山电铁贵志川线", "1日乘车券"],
          ticketName4: ["和歌山电铁贵志川线", "1日乘车券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_E.jpg",
          ],
          Doer: ["JR 和歌山站9号乘车区"],
          time: ["依车辆种类不同而各有差异"],
          Http: "http://www2.wakayama-dentetsu.co.jp/pdf/wakayama_chinese.pdf",
          Faci: ["和歌山县和歌山市美园町5丁目2 ", "（和歌山电铁和歌山站）"],
          Calendar: ["停驶日以及猫站长的上班日", "上班时间等请参照官方网站"],
          areaType: 3,
          price: "800",
        },
        // 山陽山陰
        {
          id: 22,
          areaNAme: "冈山",
          img: require("@/assets/images/allpass/erdao_A.jpg"),
          ticketName: ["儿岛循环巴士乘车券及", "丹宁布手作体验"],
          ticketName2: ["儿岛循环巴士乘车券及", "丹宁布手作体验"],
          ticketName3: ["儿岛循环巴士乘车券及", "丹宁布手作体验"],
          ticketName4: ["儿岛循环巴士乘车券及", "丹宁布手作体验"],
          tickeImg: [
            require("@/assets/images/allpass/erdao_A.jpg"),
            require("@/assets/images/allpass/erdao_B.jpg"),
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/erdao_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/erdao_D.jpg",
          ],
          Doer: ["JR 儿岛站验票口"],
          time: [
            "兑换时间：9:00 ～ 17:00",
            "丹宁布手作体验：9:00 ～ 18:00",
            "（体验时间：11:00 ～、14:00 ～）",
            "儿岛循环巴士：星期五 六 日 节日",
            "FUREAI 巴士 / TOKOHAI巴士 （每天运行）",
          ],
          Http: "http://shimoden.net/rosen/kikaku/jeans.html",
          Faci: [
            "冈山县仓敷市儿岛车站前一丁目107番地",
            "（JR 儿岛站巴士乘车处） ",
          ],
          Calendar: [
            "丹宁布手作体验休业日：年底年初",
            "儿岛循环巴士休业日：平日一～四，年底年初",
          ],
          areaType: 4,
          price: "1,000",
        },
        {
          id: 23,
          areaNAme: "冈山",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_A.jpg",
          ticketName: ["冈山格兰比亚大酒店", "西点师傅主推圣代"],
          ticketName2: ["冈山格兰比亚大酒店", "西点师傅主推圣代"],
          ticketName3: ["冈山格兰比亚大酒店", "西点师傅主推圣代"],
          ticketName4: ["冈山格兰比亚大酒店", "西点师傅主推圣代"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_D.jpg",
          ],
          Doer: ["JR 冈山站旁"],
          time: ["提供时间：12:00 ～ 17:00", "（酒店1楼大厅）"],
          Http: "https://granvia-oka.co.jp/cn/restaurant/#lum",
          Faci: ["冈山县冈山市北区站元町1-5", "（1楼Lumière柜檯）"],
          Calendar: ["无"],
          areaType: 4,
          price: "2,500",
        },
        {
          id: 24,
          areaNAme: "广岛",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_A.jpg",
          ticketName: ["岛田水产 广岛县渔场介绍与", "海上游览严岛神社"],
          ticketName2: ["岛田水产 广岛县渔场介绍", "与海上游览严岛神社"],
          ticketName3: ["岛田水产 广岛县渔场介绍", "与海上游览严岛神社"],
          ticketName4: ["岛田水产 广岛县渔场介绍", "与海上游览严岛神社"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_D.jpg",
          ],
          Doer: ["山阳本线JR宫岛口站徒歩约15分钟", "（邮轮乘船处徒歩10分钟）"],
          time: ["10:00 ～ 16:00"],
          Http: "http://shimadasuisan.com/kakigoya-cn.html",
          Faci: ["广岛县廿日市市宮島口西1-2-6", "（kaki小屋售票处）"],
          Calendar: ["无"],
          areaType: 4,
          price: "2,200",
        },
        {
          id: 25,
          areaNAme: "广岛",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
          ticketName: ["濑户田周游券"],
          ticketName2: ["濑户田周游券"],
          ticketName3: ["濑户田周游券"],
          ticketName4: ["濑户田周游券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_C.jpg",
          ],
          Doer: ["JR 尾道站内"],
          time: ["尾道站观光服务处:", "9:00 ～ 18:00"],
          Http: "https://www.ononavi.jp/fan/onotabi/guruseto/",
          Faci: ["广岛县尾道市東御所町1-1", "（尾道站观光咨询处）"],
          Calendar: ["12/29 ～ 1/3"],
          areaType: 4,
          price: "4,500",
        },
        {
          id: 26,
          areaNAme: "山口",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_A.jpg",
          ticketName: ["山口县防府市观光地", "参观通票"],
          ticketName2: ["山口县防府市观光地", "参观通票"],
          ticketName3: ["山口县防府市观光地", "参观通票"],
          ticketName4: ["山口县防府市观光地", "参观通票"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_E.jpg",
          ],
          Doer: ["JR 防府站内"],
          time: ["防府市观光服务处：9:00 ～ 18:00"],
          Http: "https://visit-hofu.jp/ticket",
          Faci: ["山口县防府市戎町1-1-1", "（JR 防府站 防府市观光案内所）"],
          Calendar: ["无"],
          areaType: 4,
          price: "1,200",
        },
        {
          id: 27,
          areaNAme: "岛根",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_A.jpg",
          ticketName: ["日本庭园由志园入场券"],
          ticketName2: ["日本庭园由志园入场券"],
          ticketName3: ["日本庭园由志园入场券"],
          ticketName4: ["日本庭园由志园入场券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_E.jpg",
          ],
          Doer: ["JR 松江站6号乘车区", "搭乘巴士约50分钟"],
          time: ["10:00 ～ 17:00（最后入园 16:30）"],
          Http: "https://www.yuushien.com/",
          Faci: ["岛根县松江市八束町", "波入1260-2（售票处）"],
          Calendar: ["12/30 ～ 12/31"],
          areaType: 4,
          price: "1,000",
        },
        // 石川
        {
          id: 28,
          areaNAme: "石川",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_A.jpg",
          ticketName: ["加贺伝统工艺乡", "汤之国之森入村券"],
          ticketName2: ["加贺伝统工艺乡", "汤之国之森入村券"],
          ticketName3: ["加贺伝统工艺乡", "汤之国之森入村券"],
          ticketName4: ["加贺伝统工艺乡", "汤之国之森入村券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_D.jpg",
          ],
          Doer: ["JR 加贺温泉站搭乘 CANBUS 环山线", "约40分钟"],
          time: ["9:00 ～ 16:30（最后入园 15:30）"],
          Http: "https://www.yunokuni.jp/mori/?lang=tw",
          Faci: ["石川县小松市栗津温泉Na3-3（售票处）"],
          Calendar: ["请参照官方网站"],
          areaType: 5,
          price: "550",
        },
        {
          id: 29,
          areaNAme: "石川",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_A.jpg",
          ticketName: ["加贺周游巴士「CANBUS」", "1日周游券"],
          ticketName2: ["加贺周游巴士「CANBUS」", "1日周游券"],
          ticketName3: ["加贺周游巴士「CANBUS」", "1日周游券"],
          ticketName4: ["加贺周游巴士「CANBUS」", "1日周游券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_D.jpg",
          ],
          Doer: ["JR 加贺温泉站南出口「MACHIDUKURI加贺周游券贩售处」"],
          time: ["MACHIDUKURI加贺周游券贩售处：", "8:30 ～ 18:00"],
          Http: "http://www.kaga-canbus.jp/chinese.php",
          Faci: ["石川县加贺市作见町RI 1-3", "（Machizukuri加贺周游券贩售处）"],
          Calendar: ["无"],
          areaType: 5,
          price: "1,100",
        },
      ],
      md3: [
        {
          id: 1,
          name: "所有地区",
          num: 29,
          areaType: 0,
        },
        {
          id: 2,
          name: "大阪",
          areaType: 2,
        },
        {
          id: 3,
          name: "京都",
          areaType: 1,
        },
      ],
      md4: [
        {
          id: 4,
          name: "石川",
          areaType: 5,
        },
      ],
      md5: [
        {
          id: 5,
          name: "滋贺·兵库·和歌山",
          areaType: 3,
        },
      ],
      md7: [
        {
          id: 6,
          name: "山阴山阳",
          areaType: 4,
        },
      ],
    };
  },
  created() {
    this.showArea();
  },
  mounted() {
    window.addEventListener("resize", this.setbanH(), false);
  },
  destroyed() {
    window.removeEventListener("resize", this.setbanH(), false);
  },
  methods: {
    // 手指触摸滑动事件
    touchstart(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    touchmove(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      this.startX - this.moveX <= 0
        ? console.log("你在往右滑")
        : console.log("你在往左滑");
      if (this.startX - this.moveX >= 100) {
        // 右滑触发
        this.drawer = true;
      }
    },
    touchmove1(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      this.startX - this.moveX <= 0
        ? console.log("你在往右滑")
        : console.log("你在往左滑");
      if (this.startX - this.moveX <= -100) {
        // 右滑触发
        this.drawer = false;
      }
    },
    touchmove2(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      this.startX - this.moveX <= 0
        ? console.log("你在往右滑")
        : console.log("你在往左滑");
      if (this.startX - this.moveX <= -100) {
        // 右滑触发
        this.innerDrawer = false;
      }
    },
    loading() {
      this.showBtn = true;
      this.$forceUpdate();
    },
    setbanH() {
      this.$nextTick(() => {
        this.banH = `${this.$refs.imgH[0].height}px`;
      });
    },
    change(item) {
      this.active = item.id;
      if (this.active === 1) {
        this.ticketDescribe = "观光设施共通券(29项设施)";
        this.planName = "关西广域周游券5天套票";
        this.planPrice = "27,700";
        this.planName2 = "关西广岛周游券5天套票";
        this.planPrice2 = "32,000";
        this.planName3 = "JR西日本全地区铁路周游券";
        this.planPrice3 = "39,000";
        this.md3[0].num = 29;
        this.showList = this.scenicSpotArr;
        let array = {
          id: 15,
          areaNAme: "兵库",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
          ticketName: ["JENOVA号淡路岛", "（明石⇔岩屋）单程船票"],
          ticketName2: ["JENOVA号淡路岛", "（明石⇔岩屋）单程船票"],
          ticketName3: ["JENOVA号淡路岛", "（明石⇔岩屋）单程船票"],
          ticketName4: ["JENOVA号淡路岛", "（明石⇔岩屋）单程船票"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_C.jpg",
          ],
          Doer: ["JR 明石站徒步约8分钟"],
          time: [
            "（平日）",
            "明石乘船时间：9:00 ～ 17:00",
            "岩屋乘船时间：9:00 ～ 17:00",
            "（星期六日 节日）",
            "明石乘船时间：9:00 ～ 17:00",
            "岩屋乘船时间： 9:00 ～ 17:00",
            "※详细时刻表请参照官方网站",
          ],
          Http: "http://www.jenova-line.co.jp/jikoku.php",
          Faci: [
            "明石乘船处：兵库県明石市本町2-10-1",
            "岩屋乘船处：兵库県淡路市岩屋925-27",
            "※可在任何一侧搭乘JENOVA船",
          ],
          Calendar: ["天候不佳时将会停驶"],
          areaType: 3,
          price: "530",
          content: "",
          content2: "",
        };
        this.id15 = this.showList.findIndex((item) => {
          if (item.id === 14) {
            return true;
          }
        });
        this.showList.splice(14, 0, array);
      } else {
        this.ticketDescribe = "观光设施共通券(16项设施)";
        this.planName = "关西地区铁路周游券3天套票方案";
        this.planName_small = "关西地区铁路周游券3天套票方案";
        this.planPrice = "19,300";
        this.planName2 = "关西广域地区铁路周游券5天套票方案";
        this.planPrice2 = "22,700";
        this.planName2_small = "关西广域地区铁路周游券5天套票方案";
        this.planName3 = "";
        this.planPrice3 = "";
        this.md3[0].num = 16;
        this.showList = this.scenicSpotArr;
        let id15 = this.showList.findIndex((item) => {
          if (item.id === 15) {
            return true;
          }
        });
        this.showList.splice(id15, 1);
      }
      this.activeAreaArr = []; //清除已选中的地区id
      this.activeArr = [1]; //切换探游券时 默认全部
    },
    openSmall(item) {
      this.ticketDetials = item;
      this.innerDrawer = true;
    },
    showArea() {
      this.showList = this.scenicSpotArr;
    },
    activeArea(item) {
      let index = 0;
      this.showList = [];
      if (item.areaType === 0) {
        // 选择了所有
        this.activeArr = [1]; //所有
        this.activeAreaArr = [];
        this.active === 1
          ? (this.showList = this.scenicSpotArr)
          : (this.showList = this.scenicSpotArr); //判断是否西日本
        return;
      }
      if (item.areaType !== 0) {
        // 没有选择全部地区并且选的是西日本
        let alldelIndex = this.checkArray(this.activeArr, 1); //找到复选地区中所有地区的下标
        alldelIndex !== -1 ? this.activeArr.splice(alldelIndex, 1) : ""; //如果有所有地区的下标就删除
        let activeArrDelIndex = this.checkArray(this.activeArr, item.id); //查找复选地区是否已经存在
        let activeAreaArrDelIndex = this.checkArray(
          this.activeAreaArr,
          item.areaType
        ); //查找复选地区是否已经存在
        activeArrDelIndex === -1
          ? this.activeArr.push(item.id)
          : this.activeArr.splice(activeArrDelIndex, 1); //如果目前地区存在就删除，不然就加入
        activeAreaArrDelIndex === -1
          ? this.activeAreaArr.push(item.areaType)
          : this.activeAreaArr.splice(activeAreaArrDelIndex, 1); //查找复选地区是否已经存在
        this.scenicSpotArr.forEach((item1) => {
          if (item.areaType === item1.areaType) {
            // 去重
            let newArr = [];
            for (let i = 0; i < this.activeArr.length; i++) {
              newArr.indexOf(this.activeArr[i]) == -1
                ? newArr.push(this.activeArr[i])
                : "";
            }
            this.activeArr = newArr;
          }
        });
        this.activeAreaArr = this.unique(this.activeAreaArr); //景点属性去重
        this.scenicSpotArr.forEach((item11) => {
          this.activeAreaArr.forEach((item12) => {
            item12 == item11.areaType ? this.showList.push(item11) : "";
            item12 == item11.areaType ? console.log(item11) : "";
          });
        });
      } else {
        this.activeArr = [1];
        this.showList = this.scenicSpotArr;
      }
    },
    unique(arr) {
      var result = [];
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] === arr[j]) {
            j = ++i;
          }
        }
        result.push(arr[i]);
      }
      return result;
    },
    checkArray(arr, target) {
      return arr.indexOf(target);
    },
    handleClose(done) {
      done();
    },
    splitBatName(str) {
      return str;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/index2.scss";
.touch {
  touch-action: pan-y !important;
}
.loadImg {
  width: 100%;
  height: auto;
}
.main-package {
  z-index: 0 !important;
}
::v-deep .el-drawer__body {
  overflow: auto;
}
::v-deep .el-drawer__container ::-webkit-scrollbar {
  display: none;
}
::v-deep .el-carousel__indicator--horizontal {
  padding: 0 4px;
}

::v-deep .el-drawer {
  width: 100% !important;
}

::v-deep .el-drawer__header {
  display: block;
  color: rgb(255, 152, 0);
  padding: 14px 16px;
  margin-bottom: 0;
  background-color: rgb(255, 237, 147);
  position: relative;
  height: 27px;
  line-height: 27px;
}

::v-deep .el-icon-close:before {
  content: "\e6de";
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #fb7b05;
  border-radius: 20px;
}

::v-deep .el-drawer__close-btn {
  border: 0;
  outline: none;
}
::v-deep .el-carousel__button {
  display: block;
  opacity: 0.48;
  width: 7px;
  height: 7px;
  background-color: #bcbfbf;
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
}

::v-deep .el-carousel__indicator.is-active button {
  width: 7px;
  height: 7px;
  background-color: #ff8033;
  border-radius: 50%;
}

::v-deep .el-carousel__indicators--horizontal {
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 360px) {
  ::v-deep .el-carousel__indicators--horizontal {
    bottom: 19%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>